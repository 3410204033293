import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type Actions = {
    loading: (loading: boolean, blockScroll?: boolean) => void
}

type State = {
    isLoading: boolean
    blockScroll: boolean
}

const initialState = {
    isLoading: false,
    blockScroll: true,
}

export const useLoadingStore = create(
    immer<Actions & State>((set) => ({
        ...initialState,
        loading: (loading, blockScroll = true) =>
            set((state) => {
                state.isLoading = loading
                state.blockScroll = blockScroll
            }),
    }))
)
