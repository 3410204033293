'use client'

import { useEffect } from 'react'
import clsx from 'clsx'

import LoadingIcon from '@/components/Base/LoadingIcon'
import { useLoadingStore } from '@/stores/loading'

export default function LoadingContainer() {
    const { isLoading, blockScroll } = useLoadingStore()

    useEffect(() => {
        if (blockScroll) {
            const html = document.getElementsByTagName('html')[0]
            if (isLoading) {
                html.style.overflow = 'hidden'
                return () => {
                    html.style.overflow = 'scroll'
                }
            } else {
                html.style.overflow = 'scroll'
            }
        }
    }, [isLoading, blockScroll])

    return (
        <div
            className={clsx(
                isLoading ? 'flex items-center justify-center' : 'hidden',
                'fixed z-[100] h-screen w-screen bg-black/60'
            )}
        >
            <div className='h-16 w-16'>
                <LoadingIcon />
            </div>
        </div>
    )
}
